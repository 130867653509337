.scrollableContainer {
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  height: 50vh;
  overflow-y: auto;
  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.icon {
  height: 2vh;
  width: 2vh;
  background-color: black;
  cursor: pointer;
  padding: 1vh;
  border-radius: 20%;
  transition: background-color 0.3s ease;
}

.icon:hover {
  background-color: rgb(255, 255, 255, 0.17);
}
