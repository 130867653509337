/* Default for mobile */
.responsive-layout {
  display: flex;
  flex-direction: column;
}

.logo-container {
  width: 100%; /* Full width for mobile */
  margin-bottom: 20px;
  position: relative; /* Required for pseudo-element positioning */
}

.form-container {
  width: 100%; /* Full width for mobile */
}

.logo {
  height: 100px;
}

.cross {
  height: 60px;
  width: 60px;
}

/* For larger screens (e.g., web) */
@media (min-width: 768px) {
  .responsive-layout {
    flex-direction: row; /* Horizontal layout for web */
    height: 100vh; /* Ensure full height of the viewport */
  }

  .logo-container {
    width: 60%;
    height: 100%; /* Adjust the logo container to take 60% of screen width */
    margin-bottom: 0;
    position: relative; /* Ensure positioning for pseudo-element */
    z-index: 1; /* Ensure content is above the background */
  }

  /* Add the background image with opacity using ::before */
  .logo-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('assets/images/thumbnail_partner.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Control opacity here */
    z-index: -1; /* Ensure the image stays behind content */
  }

  .form-container {
    width: 35%;
    margin: 0 auto;
  }

  .logo {
    height: 10vw;
  }

  .cross {
    height: 80px;
    width: 80px;
  }

  .sso-buttons-container {
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .logo {
    height: 70px;
  }
}
